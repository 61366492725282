import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/nearby',
    name: 'Nearby',
    component: () => import(/* webpackChunkName: "about" */ '../views/propaganda/Nearby.vue'),
  },
  {
    path: '/investment',
    name: 'Investment',
    component: () => import(/* webpackChunkName: "about" */ '../views/propaganda/Investment.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/default.vue'),
    children: [{
      path: '/study',
      name: 'Study',
      component: () => import(/* webpackChunkName: "about" */ '../views/meus/Study.vue'),
    }, {
      path: '/administration',
      name: 'Administration',
      component: () => import(/* webpackChunkName: "about" */ '../views/meus/Administration.vue'),
    }, {
      path: '/curriculum',
      name: 'Curriculum',
      component: () => import(/* webpackChunkName: "about" */ '../views/meus/Curriculum.vue'),
    }, {
      path: '/certificate',
      name: 'Certificate',
      component: () => import(/* webpackChunkName: "about" */ '../views/meus/Certificate.vue'),
    }, {
      path: '/compete',
      name: 'Compete',
      component: () => import(/* webpackChunkName: "about" */ '../views/meus/Compete.vue'),
    }, {
      path: '/news',
      name: 'News',
      component: () => import(/* webpackChunkName: "about" */ '../views/meus/News.vue'),
    },
    {
      path: '/introduce',
      name: 'Introduce',
      component: () => import(/* webpackChunkName: "about" */ '../views/meus/Introduce.vue'),
    },
    {
      path: '/article',
      name: 'Article',
      component: () => import(/* webpackChunkName: "about" */ '../views/list/Article.vue'),
    },
    {
      path: '/details',
      name: 'Details',
      component: () => import(/* webpackChunkName: "about" */ '../views/meus/Notice/Details.vue'),
    },
    {
      path: '/notice',
      name: 'Notice',
      component: () => import(/* webpackChunkName: "about" */ '../views/meus/Notice/Notice.vue'),
    },
    {
      path: '/detailslist',
      name: 'DetailsList',
      component: () => import(/* webpackChunkName: "about" */ '../views/meus/Notice/DetailsList.vue'),
    },
    {
      path: '/detailscode',
      name: 'DetailsCode',
      component: () => import(/* webpackChunkName: "about" */ '../views/meus/Notice/DetailsCode.vue'),
    },
    {
      path: '/home',
      name: 'Home',
      component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    },]
  },

]

const router = new VueRouter({
  mode: 'hash',
  // base: "/history",
  routes
})

export default router
