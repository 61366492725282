<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    let a = localStorage.getItem("globalColor");
    let b = localStorage.getItem("theme");
    this.$store.commit("setGlobalColor", JSON.parse(a));
    this.$store.commit("setTheme", b);

  },

};
</script>
<style lang="less"></style>
