import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    globalColor: null,
    theme: 'lightCss'
  },
  mutations: {
    setTheme(state, data) {
      state.theme = data;
      localStorage.setItem('theme', data);
    },
    setGlobalColor(state, data) {
      state.globalColor = data;
      localStorage.setItem('globalColor', data);
    },
  },
  getters: {
  },
  actions: {},
  modules: {},
})

