class IspSt {
  port = null;
  _ele = null;
  bOpened = 0;
  wr;
  src = "";
  buf = new Uint8Array(65535);
  buf2 = [];

  // 对外提供单个接口
  async act(url, bTryUseLast, ele) {
    this.init(ele);
    await this.fetchSrc(url);
    if (!this.bOpened) {
      this.showHint = true
      await this.portOpen(bTryUseLast);
    }

    if (this.buf2.length <= 0) {
      console.log('warn, buf2 == 0, src:');
      console.log(this.src);
    }
    console.log('buf2 len:' + this.buf2.length);
    if (!this.bOpened) {
      console.log('scom not opened'); return;

    }
    {
      await this.load();
    }
    if (this.bOpened) {
      this.showHint = false
      await this.portClose(0);
    }
  }

  pad(num, n) {
    let len = num.toString().length,
      pre = "";
    while (len < n) {
      pre += "0";
      len++;
    }
    return pre + num;
  } // 左侧补0

  stamp() {
    let d = new Date();
    let r = d.getFullYear().toString();
    r += this.pad(d.getMonth() + 1, 2);
    r += this.pad(d.getDate(), 2);
    r += this.pad(d.getHours(), 2);
    r += this.pad(d.getMinutes(), 2);
    r += this.pad(d.getSeconds(), 2);
    return r;
  }

  arrToStr(arr) {
    let i,
      len = arr.length - 1,
      s = "",
      totalS = [];
    for (i = 0; i < len; i++) {
      s = arr[i].toString(16);
      if (s.length <= 1) {
        s = "0" + s;
      }
      totalS[i] = s;
    }
    s = arr[len].toString(16);
    if (s.length <= 1) {
      s = "0" + s;
    }
    totalS[len] = s;
    return totalS.join(" ").toUpperCase();
  }

  delay(ms) {
    let p;
    p = new Promise((succ) => {
      setTimeout(function () {
        succ();
      }, ms);
    });
    return p;
  }

  checkCreate(arr) {
    let len = arr.length,
      i,
      sum = 0;
    len -= 2;
    for (i = 2; i < len; i++) {
      sum += arr[i];
    }
    arr[len] = sum;
  }

  init(ele) {
    if (ele) {
      this._ele = ele;
    }
  }
  hint(s) {
    if (this._ele) {
      this._ele.innerText = s;
    }
  }

  async fetchSrc(url) {
    let blob, txt;
    if (url.indexOf("?") > 0) {
      url += "&";
    } else {
      url += "?";
    }
    url += "stamp=" + this.stamp();
    blob = await (await fetch(url)).blob();
    txt = await new Promise((succ) => {
      let rd = new FileReader();
      rd.readAsText(blob, "utf-8");
      rd.onload = function () {
        succ(this.result);
      };
    });
    this.src = txt;
    let i = 0,
      s = "",
      bEnd = 0,
      bufLen = 0;
    let len, addr, type, j, pay;
    let src = this.src;
    while (!bEnd) {
      if (src[i] != ":") {
        console.log("err1, src[" + i + "]:" + src[i]);
        return;
      }
      i++;
      s = src[i] + src[i + 1];
      i += 2;
      len = parseInt(s, 16);
      s = src[i] + src[i + 1] + src[i + 2] + src[i + 3];
      i += 4;
      addr = parseInt(s, 16);
      s = src[i] + src[i + 1];
      i += 2;
      type = parseInt(s, 16);
      if (type == 0) {
        if (len > 0) {
          for (j = 0; j < len; j++) {
            s = src[i] + src[i + 1];
            i += 2;
            pay = parseInt(s, 16);
            this.buf[addr + j] = pay;
          }
          if (bufLen < addr + len) {
            bufLen = addr + len;
          }
        }
      } else if (type == 1) {
        bEnd = 1;
        break;
      }
      i += 2;
      if (src[i] == "\r" || src[i] == "\n") {
        i++;
      }
      if (src[i] == "\r" || src[i] == "\n") {
        i++;
      }
    }
    let sum, len2;
    sum = Math.ceil(bufLen / 512);
    len2 = sum * 512;
    this.buf2 = new Uint8Array(len2);

    for (i = 0; i < bufLen; i++) {
      this.buf2[i] = this.buf[i];
    }
    for (i = bufLen; i < len2; i++) {
      this.buf2[i] = 0xff;
    }
    this.buf3 = this.buf2;
    console.log(this.buf2, this.buf2.length, "this.buf2");
    this.hint(
      "下载" + (bufLen > 0 ? "成功" : "失败") + "，文件大小为:" + this.src.length
    );
  }

  async portOpen(bTryUseLast) {
    if (this.bOpened) {
      return;
    }

    if (!"serial" in navigator) {
      console.log("scom unavailable");
      this.hint("浏览器暂不支持通信口");
      return;
    }
    let s = location.host;
    if (s.indexOf("localhost") < 0 && "https:" != location.protocol) {
      console.log("warn: not https");
    }

    const ports = await navigator.serial.getPorts();
    if (bTryUseLast && ports.length > 0) {
      this.port = ports[0];
    } else {
      this.port = await navigator.serial.requestPort();
    }
    await this.port.open({ baudRate: 4800 });
    this.wr = this.port.writable.getWriter();
    this.bOpened = 1;
    console.log("scom open succ", this.wr);
    this.hint("通信口已成功打开");

  }

  async portClose(bHint) {
    if (!this.bOpened) {
      return;
    }
    this.wr.releaseLock();
    await this.port.close();
    this.bOpened = 0;
    console.log("scom close.");
    if (bHint) {
      this.hint("通信口已关闭");
    }
  }

  async write0(arr) {
    await this.wr.write(arr);
  }

  async write(arr) {
    await this.wr.write(arr);
  }
  async writing(arr, tm) {
    const poll = setInterval(async () => {
      if (this.rx.length > 0) {
        clearInterval(poll);
      } else {
        await this.write0(arr);
      }
    }, tm);
  }

  async read1st() {
    let preLen;
    let p;
    this.rx = new Uint8Array(0);
    p = new Promise(async (succ) => {
      const rd = this.port.readable.getReader();
      let cntr = 0;
      let expectLen = 0,
        len;
      while (1) {
        const { value, done } = await rd.read();
        if (value.length > 0) {
          preLen = this.rx.length;
          if (preLen == 0) {
            if (value[0] != 0x68 && value[0] != 0x46) {
              continue;
            }
          }
          let arr2 = new Uint8Array(this.rx.length + value.length);
          arr2.set(this.rx);
          arr2.set(value, this.rx.length);
          this.rx = arr2;
          len = this.rx.length;
          if (this.rx[0] == 0x68) {
            if (expectLen == 0 && len >= 3) {
              expectLen = this.rx[2];
            }
            if (expectLen > 0 && len >= expectLen) {
              rd.releaseLock();
              succ();
              break;
            } else {
            }
          } else if (this.rx[0] == 0x46) {
            if (expectLen == 0 && len >= 5) {
              expectLen = this.rx[4];
            }
            if (expectLen > 0 && len >= expectLen) {
              rd.releaseLock();
              succ();
              break;
            } else {
            }
          } else {
          }
        } else {
        }
        if (done) {
          rd.releaseLock();
          break;
        }
      }
    });
    return p;
  }

  async read() {
    let p;
    this.rx = new Uint8Array(0);
    p = new Promise(async (succ) => {
      const rd = this.port.readable.getReader();
      let preLen;
      let expectLen = 0,
        len;
      while (1) {
        const { value, done } = await rd.read();
        if (value.length > 0) {
          preLen = this.rx.length;
          let arr2 = new Uint8Array(this.rx.length + value.length);
          arr2.set(this.rx);
          arr2.set(value, this.rx.length);
          this.rx = arr2;
          len = this.rx.length;
          if (expectLen == 0 && len >= 5) {
            expectLen = 2 + this.rx[4];
          }
          if (expectLen > 0 && len >= expectLen) {
            rd.releaseLock();
            succ();
            break;
          } else {
          }
        } else {
        }
        if (done) {
          rd.releaseLock();
          break;
        }
      }
    });
    return p;
  }

  bLoading = 0;
  // 程序下载到目标板
  async load() {
    if (this.bLoading) {
      return;
    }
    let i, j;
    let gap = 50,
      gap2 = 100;
    this.bLoading = 1;
    let tx = new Uint8Array([0x7f]);
    this.hint("正在检测学习板...");
    this.rx = new Uint8Array(0);
    await this.writing(tx, 120);
    await this.read1st();
    this.hint("已连接");
    tx = new Uint8Array([
      0x46,
      0xb9,
      0x6a,
      0x00,
      0x0c,
      0x8f,
      0xff,
      0xb8,
      0x00,
      0x90,
      0x50,
      0x82,
      0x1e,
      0x16,
    ]);
    await this.write(tx);
    await this.read();

    tx = new Uint8Array([
      0x46,
      0xb9,
      0x6a,
      0x00,
      0x0b,
      0x8e,
      0xff,
      0xb8,
      0x00,
      0x90,
      0x50,
      0x9a,
      0x16,
    ]);
    await this.write(tx);
    await this.read();
    tx = new Uint8Array([
      0x46,
      0xb9,
      0x6a,
      0x00,
      0x0c,
      0x80,
      0x00,
      0x00,
      0x36,
      0x01,
      0xf0,
      0x02,
      0x1f,
      0x16,
    ]);
    i = 0;
    while (i < 5) {
      await this.write(tx);
      await this.read();
      i++;
    }

    let len, blockSum, packSum, packLen, percent;
    len = this.buf2.length;
    blockSum = Math.ceil(len / 512);
    packSum = Math.ceil(len / 128);
    this.hint("正在写入代码...");
    tx = new Uint8Array([
      0x46,
      0xb9,
      0x6a,
      0x00,
      0x0d,
      0x84,
      0x00,
      0x33,
      0x33,
      0x33,
      0x33,
      0x33,
      0x33,
      0x00,
      0x16,
    ]);
    tx[6] = blockSum;
    this.checkCreate(tx);
    await this.write(tx);
    await this.read();
    console.log("scom response");
    await this.delay(20);

    tx = new Uint8Array(142);
    let head,
      headLen = 12;
    head = new Uint8Array([
      0x46,
      0xb9,
      0x6a,
      0x00,
      0x8c,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x80,
    ]);
    for (i = 0; i < headLen; i++) {
      tx[i] = head[i];
    }
    tx[141] = 0x16;
    i = 0;
    packLen = 128;
    let addr;
    while (i < packSum) {
      percent = parseInt(((i + 1) * 100) / packSum);
      if (percent > 100) {
        percent = 100;
      }
      this.hint("正在写入代码 " + percent + " % ...");
      addr = i * 128;
      tx[8] = Math.floor(addr / 256);
      tx[9] = parseInt(addr % 256);
      for (j = 0; j < packLen; j++) {
        tx[12 + j] = this.buf2[addr + j];
      }
      this.checkCreate(tx);
      await this.write(tx);
      await this.read();
      await this.delay(gap);
      i++;
    }
    this.hint("代码写入成功...");

    await this.delay(10);
    tx = new Uint8Array([
      0x46,
      0xb9,
      0x6a,
      0x00,
      0x0a,
      0x8d,
      0xfd,
      0xff,
      0xf6,
      0xff,
      0xf2,
      0x16,
    ]);
    await this.write(tx);
    await this.read();
    await this.delay(gap2);

    tx = new Uint8Array([0x46, 0xb9, 0x6a, 0x00, 0x06, 0x50, 0xc0, 0x16]);
    await this.write(tx);
    await this.read();
    await this.delay(gap2);

    tx = new Uint8Array([0x46, 0xb9, 0x6a, 0x00, 0x06, 0xff, 0x6f, 0x16]);
    await this.write(tx);
    await this.delay(gap2);

    tx = new Uint8Array([0x46, 0xb9, 0x6a, 0x00, 0x06, 0x82, 0xf2, 0x16]);
    await this.write(tx);
    await this.delay(gap2);
    this.hint("");
    this.bLoading = 0;
  }
}



export default IspSt